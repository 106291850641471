import API from "@/httpsAPI/API";
import request from "@/utils/request";

// 项目阶段递归查询（后台组装递归树形）
export function getProjectManageStageList(params) {
  return request({
    url:API.projectStageList + '/' + params.projectId,
    method:'post',
    data:params
  })
}

// 项目阶段查询（前端组装树形结构数据）
export function getProjectManageStageList2(params) {
  return request({
    url:API.projectStageList2 + '/' + params.projectId,
    method:'post',
    data:params
  })
}

// 项目甘特图查询
export function getProjectManageGantt(params) {
  return request({
    url:API.projectStageGantt + '/' + params.projectId ,
    method:'post',
    data:{}
  })
}

// 新建项目阶段
export function addStage(params) {
  return request({
    url: "/project/plan/add",
    method: "post",
    data:params
  });
}

// 修改项目阶段
export function updateStage(params) {
  return request({
    url: "/project/plan/update",
    method: "post",
    data:params
  });
}

// 删除项目阶段
export function deleteStage(params) {
  return request({
    url: "/project/plan/delete/" + params.id,
    method: "post",
    data:{}
  });
}

// 项目阶段详情
export function detailStage(params) {
  return request({
    url: "/project/plan/detail/" + params.id,
    method: "post",
    data:{}
  });
}

// 删除阶段关联任务
export function deleteTask(params) {
  return request({
    url: '/project/plan/delete-task/' + params.id,
    method:'post',
    data:params
  })
}

// 查询阶段关联任务详情
export function detailTask(params) {
  return request({
    url: "/project/plan/detail-task/" + params.id,
    method: "post",
    data:{}
  });
}

// 查询已关联任务
export function getRelatedTaskList(params) {
  return request({
    url: '/project/plan/related-task-list/' + params.relatId,
    method:'post',
    data: params
  })
}

// 删除任务和阶段关联关系
export function deleteRelation(params) {
  return request({
    url: '/project/plan/delete-relation/' + params.id,
    method:'post',
    data:params
  })
}

